<template>
	<div>
		<list-template
				:current-page="page"
				:is-check-input-box="false"
				:loading="loading"
				:table-config="tableConfig"
				:table-data="tableData"
				:total="total"
				@onChangePage="handleCurrentChange"
				@onHandle="handleEdit"
        @onExportData="onExport"
		>
			<template slot="title">
				<search-block ref="search" :search-config="searchConfig" :search-loading="loading" @onReset="search = {}" @onSearch="onSearch">
          <!--					<template slot="search">-->
          <!--						<el-button size="mini" style="margin-left: 18rem" type="primary" @click="onExport">导出数据</el-button>-->
          <!--					</template>-->
				</search-block>
			</template>
		</list-template>
	</div>
</template>
<script>
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue"
import SearchBlock from "@/components/SearchBlock.vue";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {SearchBlock, ListTemplate},
	data() {
		return {
			loading: true,
			search: {},
			searchConfig: [{
				prop: "student_name",
				placeholder: "搜索学生姓名",
			}, {
				prop: "parent_name",
				placeholder: "搜索家长姓名",
			}, {
				prop: "mobile",
				placeholder: "搜索联系电话",
			}, {
				prop: "at",
				start: "筛选开始提交时间",
				end: '筛选结束提交时间',
				tag: 'daterange',
			}],
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,
			tableConfig: [
				{prop: "mobile", label: "联系电话"},
				{prop: "name", label: "联系人姓名"},
				{prop: "identification_text", label: "联系人身份"},
				{prop: "parent_name", label: "家长姓名"},
				{prop: "student_name", label: "学生姓名"},
				{prop: "school_name", label: "咨询学校"},
				{prop: "created_at", label: "提交时间"},
				{
					prop: "handle",
					label: "操作",
					width: "80rem",
					handle: true,
					render() {
						return ["查看"]
					}
				},
			],
		}
	},
	created() {
		this.$store.commit("setPage", 1);
		this.getData()
	},
	activated() {
		this.getData()
	},
	computed: {
		...mapState(["page"])
	},
	methods: {
		getData() {
			this.loading = true
			let search = JSON.parse(JSON.stringify(this.search))
			if (search.at) {
				search.start_at = search.at[0]
				search.end_at = search.at[1]
				delete search.at
			}
			let params = {...search, page: this.page}
			this.tableData = [];
			this.$_register('api/recruit-v2/account-manage/get-list', {params}).then(res => {
				let data = res.data.data
				this.tableData = data.list
				this.total = data.page.total
			}).finally(() => {
				this.loading = false
			})
		},

		onSearch(val) {
			this.$store.commit('setPage', 1);
			this.search = val;
			this.getData(val)
		},

		handleEdit(row, text) {
				this.$router.push('./look?id=' + row.id)
		},

		// 当前页改变触发
		handleCurrentChange(val) {
			//当前页赋值给currentPage
			this.$store.commit("setPage", val);
			//拉取数据
			this.getData()
		},


		// 导出数据
		onExport() {
			let search = JSON.parse(JSON.stringify(this.search))
			if (search.at) {
				search.start_at = search.at[0]
				search.end_at = search.at[1]
				delete search.at
			}
			let params = {...search}
			this.$_register.get('api/recruit-v2/account-manage/export', {params}).then(res => {
				this.$tools.download('预报名客户', res.data)
			})
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .t-search-block .el-input {

}

.role-list {
	padding-top: 24rem;
}

.button {
	box-sizing: border-box;
	width: 90rem;
	height: 34rem;
	margin-bottom: 30rem;
	margin-left: 34rem;
	opacity: 1;
	border-radius: 4rem;
}

.table {
	margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
	font-size: 14rem;
	font-weight: normal;
	box-sizing: border-box;
	height: 48rem;
	text-align: left;
	color: #333333;
}

.header {
	font-size: 16rem;
	font-weight: bold;
	line-height: 24rem;
	position: fixed;
	top: 120rem;
	left: 300rem;
	letter-spacing: 3.5rem;
	color: #333333;
}

.el-button--text {
	font-size: 14rem;
	font-weight: normal;
	margin-right: 40rem;
	color: #3491fa;
}
</style>
